body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    overflow: hidden;
}

html {
    height: 100%;
}

.App {
    text-align: left;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1, h2 {
    text-transform: uppercase;
}




h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: whitesmoke;
}

/* Style for the h2 heading */
h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: whitesmoke;
}

.App-link {
    color: #61dafb;
}

.button {
    background-color: #003F3F;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin: 15px;
}

.button:hover {
    background-color: #29B9DB; /* Change background color on hover */
}

.navigate-button {
    background-color: #29B9DB;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 20px;
}

.navigate-button:hover {
    background-color: #003F3F;
}

/* Style for the PDF list */
.pdf-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns for 2 rows */
    gap: 20px;
}

/* Style for each PDF item */
.pdf-item {
    margin: 0;
}

/* Style for the PDF download button */
.pdf-button {
    background-color: #29B9DB;
    color: #fff;
    border: none;
    padding: 20px; /* Adjust the padding for button size */
    border-radius: 10px; /* Make buttons quadratic */
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.pdf-button:nth-child(1) {
    grid-column: span 2; /* This button spans 2 columns for a rectangular shape */
}

.pdf-button:nth-child(2) {
    grid-row: span 2; /* This button spans 2 rows for a vertical rectangle */
}

.pdf-button:nth-child(3) {
    border-radius: 50%; /* This button is circular */
}

.pdf-button:nth-child(4) {
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%); /* This button is triangular */
}

.pdf-button:hover {
    background-color: #FFC466;
}

/* Remove default button styles */
button:focus {
    outline: none;
}
