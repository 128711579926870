body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  overflow: hidden;
  background-color: #edf8fe;
}

.logo {
  width: 200px;
  height: auto;
  margin-top: auto;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;
}

html {
  height: 100%;
}

.App {
  text-align: left;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-header-class {
  position: static;
  top: 10px;
  z-index: 100;
  width: 85%;
  height: 20vh;
  /* padding: 20px; */
  margin: auto;
  text-align: left;
  color: whitesmoke;
  background-size: cover;
  background-position: center;
  /* border-radius: 25px; */
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
}

.new-header-class h1,
.new-header-class p {
  display: block;
  color: black;
  text-align: left;
  padding: 2px 2px;
}

@media screen and (max-width: 768px) {
  .new-header-class h1, .new-header-class p {
    text-align: left; /* Center align on smaller screens */
    font-size: 1.2rem;
  }
}

.title-background{
}

.page-description {
  width: 85%;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: black;
  text-align: left;
  padding: 2px 2px;
  margin: 0;
}

.column {
  width: 45%;

}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* border-radius: 25px; */
  width: 100%;
  height: auto;
  box-shadow: none;
  text-align: center;
  margin-top: 0;
  background-color: white;
}


.connected-message {
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
}


.App-link {
  color: #29B9DB;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Pop-Up CSS-Styles */

.popup {
  border: 1px solid #29B9DB;
  padding: 35px;
  background-color: #f5f5f5;
  border-radius: 5px;
  max-width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999; /* Add a higher z-index value */
}


.popup-header {
  font-weight: bold;
  font-size: 14px;
  color: #FFC466;
}

.popup-icon {
  font-weight: bold;
  color: #FFC466;
  margin-right: 5px;
}

.popup h3 {
  font-weight: bold;
  color: #FFC466;
}

.popup-list {
  list-style: none;
  padding-left: 0;
  text-align: left;
}

.popup-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  word-wrap: break-word;
}

.popup-button {
  margin-top: 10px;
}

